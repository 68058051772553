<template>
  <kk-container
    ref="KKContainer"
    :isShowEmpty="isShowEmpty"
    :isShowLoading="isShowLoading"
    loadingText="查询中"
    :emptyTitle="emptyTitle"
    :emptyTopOffset="140"
  >
    <template slot="header">
      <search
        class="search-bar"
        ref="searchBar"
        bgColor="#F3F4F5"
        :isDefaultFocus="defaultFocus()"
        :isShowSuffix="true"
        :maxlength="50"
        placeholder="搜索商品名称"
        @search="toSearchProdPage"
        :searchValue.sync="prodName"
      >
        <div @click="toSearchProdPage" class="search-action">
          {{ "搜索" }}
        </div>
      </search>
    </template>
    <template slot="main">
      <prod-info
        v-if="searchProdList && searchProdList.length > 0"
        :prodList="searchProdList"
      ></prod-info>
      <wx-inner-config v-if="isInWeixin"></wx-inner-config>
    </template>
  </kk-container>
</template>

<script>
import search from "@/components/search/search";
import ProdInfo from "./components/prodInfo.vue";
import KkContainer from "@/components/KkContainer";
import { Toast } from "vant";
import WxInnerConfig from "@/components/wxInnerConfig";
import { isWeixin, isIOS } from "@/utils/utils";
import { searchCorrigendumByName } from "@/api/book";
var wx = require("weixin-js-sdk");
export default {
  components: {
    search,
    ProdInfo,
    KkContainer,
    WxInnerConfig,
  },
  computed: {
    isInWeixin() {
      return isWeixin();
    },
  },
  data() {
    return {
      isShowEmpty: false,
      emptyTitle: "未搜索到相关商品噢～",
      prodName: "",
      isShowLoading: false,
      isDefaultFocus: true,
      routeBack: true,
      searchProdList: [],
    };
  },
  mounted() {
    if (isWeixin()) {
      wx.ready(function () {
        const title = "库课图书_搜索";
        const desc = "库课图书_搜索";
        const imgUrl = "https://oss.kuke99.com/img/book/share/logo.png";
        //// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后
        wx.updateTimelineShareData({
          title: title, // 分享标题
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: imgUrl, // 分享图标
          success: function () {},
        });
        wx.updateAppMessageShareData({
          title: title, // 分享标题
          desc: desc || "", // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: imgUrl, // 分享图标
          success: function () {},
        });
      });
      wx.error(function () {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    }
  },
  activated() {
    if (this.routeBack) {
      if (!isIOS()) {
        this.isDefaultFocus = true;
        this.$refs.searchBar.autoFocus();
      }
      this.prodName = this.$route.query.prodName;
      if (this.prodName) {
        this.toSearchProdPage();
      }
    }
  },
  deactivated() {},
  beforeRouteLeave(to, from, next) {
    if (to.path === "/bookCorrect") {
      //回退首页
      this.searchProdList = [];
      this.prodName = "";
      this.isShowEmpty = false;
      this.routeBack = true;
    } else {
      this.routeBack = false;
    }
    next();
  },
  methods: {
    defaultFocus() {
      if (isIOS()) {
        return false;
      } else {
        return this.isDefaultFocus;
      }
    },
    /**
     * 搜索框搜索按钮点击事件
     */
    toSearchProdPage() {
      this.searchProdList = [];
      this.isShowEmpty = false;
      if (this.prodName && this.prodName.trim().length > 0) {
        this.search();
      } else {
        Toast("请先输入搜索词哦");
      }
    },
    /**
     * 搜索
     */
    async search() {
      let param = {
        prodName: this.prodName,
      };
      if (this.isShowLoading) return;
      this.isShowLoading = true;
      let result = await searchCorrigendumByName(param);
      setTimeout(() => {
        this.isShowLoading = false;
      }, 30);
      if (result) {
        this.searchProdList.push(...result.list);
      }
      if (this.searchProdList.length == 0) {
        this.isShowEmpty = true;
      }
    },
  },
};
</script>

<style scoped>
.search-bar {
  width: 100%;
  padding: 20px 28px;
}

.search-action {
  padding-left: 24px;
  width: 70px;
}
</style>
